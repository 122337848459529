@import url('https://fonts.googleapis.com/css2?family=BioRhyme&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');

table {
  width: 90%;
  margin: auto;
  border-collapse: collapse;
  background-color: #1b1b1b; /* Dark background */
  color: white; /* White text */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensures the rounded corners are not overlapped by child elements */
}

th, td {
  text-align: left;
  padding: 12px 20px;
}

thead {
  /* color: #02A38A;*/
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  background-color: #333;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Adjust this value if your table's headers need to start sticking after some space from the top */
  background-color: #fff; /* Ensure the background isn't transparent */
  z-index: 1; /* Make sure the header is above other content */
}

th:first-child, td:first-child {

  padding-left: 40px; /* More space on the first cell for the visual effect on the left */
}

tbody tr {
  border-bottom: 1px solid #2e2e2e; /* Subtle border for rows */
}

tbody tr:last-child {
  border-bottom: none;
}

tbody tr:hover {
  background-color: #5DC1B4; /* Hover effect for rows */
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.33); /* Light gray tint */
}

tr:last-child {
  background-color: #02A38A; /* Darker shade for emphasis */
  color: #FFF;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

/* Assuming you are using a div for the vertical green line on the right */
.vertical-line {
  background-color: #02A38A; /* Lime green color */
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.btn-container{
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.button {
  margin: 1rem;
  padding: 1rem;
  border: 2px solid #02A38A;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  border-radius: 10px;
  background: #333;
}

.button:hover {
  border-color: #02A38A;
  background: #02A38A;
  color: white;
}

.starting-number-container {
   display: flex;
    align-items: center;
    gap: .5rem;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    text-transform: uppercase;
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  color: white;
  text-align: center;
  padding: 10px 0;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}