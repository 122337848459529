.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black tint */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Ensure it's above other content */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    text-align: center;
    width: 90%; /* Responsive width */
  }
  
  .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical; /* Allows the textarea to be resized vertically */
  }

  .btn {
    margin: 1rem;
    padding: 1rem;
    border: 2px solid #02A38A;
    color: #02A38A;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    border-radius: 10px;
    background: white;
  }
  
  .btn:hover {
    border-color: #02A38A;
    background: #02A38A;
    color: white;
  }
  